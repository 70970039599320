<template>
  <v-container>
    <v-layout row wrap justify-space-between class="mb-2">
      <v-flex xs12 sm4 md4 lg4 v-if="!currentBusiness" class="py-1 px-1">
        <v-select
          dense
          solo
          class="caption"
          :label="$t('select business')"
          item-text="businessName"
          item-value="id"
          :items="ownersBusiness"
          v-model="selectedShop"
          @change="onSelectShop"
        ></v-select>
      </v-flex>
      <v-flex xs12 sm4 md4 lg4 class="text-right py-1">
        <v-text-field
          v-model="search"
          dense
          solo
          :placeholder="$t('search suppliers')"
        ></v-text-field>
      </v-flex>
      <v-spacer></v-spacer>
      <v-flex xs12 sm4 md4 lg4 class="py-2 text-right">
        <v-btn
          :color="$store.state.primaryColor"
          outlined
          small
          @click="newSupplier"
        >
          {{ $t("new supplier") }}
        </v-btn>
      </v-flex>
    </v-layout>

    <v-divider></v-divider>

    <v-layout row wrap justify-center>
      <v-flex xs12 sm12 md12 lg12>
        <v-data-table
          :headers="headers"
          :loading="loading"
          :items="suppliers"
          :search="search"
          :items-per-page="20"
        >
          <template v-slot:[`item.address.country`]="{ item }">
            {{ item.address.country }}
          </template>
          <template v-slot:[`item.action`]="{ item }">
            <v-icon
              small
              left
              :color="$store.state.primaryColor"
              @click="toSupplierDetails(item.id)"
              >visibility</v-icon
            >
          </template>
        </v-data-table>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import db from "@/plugins/fb";

export default {
  data: () => ({
    loading: true,
    search: "",

    selectedShop: "",

    suppliers: [],
  }),
  computed: {
    currentBusiness() {
      return this.$store.getters.getCurrentBusiness;
    },
    ownersBusiness() {
      return this.$store.getters.getBusinesses;
    },
    ownersBusinessIds() {
      return this.$store.getters.getBusinessId;
    },
    businessOwner() {
      return this.$store.state.currentUser;
    },
    headers() {
      return [
        {
          text: this.$t("supplier name"),
          value: "name",
          class: "blue-grey darken-3 white--text font-weight-regular",
        },
        {
          text: this.$t("email"),
          value: "email",
          class: "blue-grey darken-3 white--text font-weight-regular",
        },
        {
          text: this.$t("telephone"),
          value: "phone",
          class: "blue-grey darken-3 white--text font-weight-regular",
        },
        {
          text: this.$t("country"),
          value: "address.country",
          class: "blue-grey darken-3 white--text font-weight-regular",
        },
        {
          text: this.$t("actions"),
          value: "action",
          class: "blue-grey darken-3 white--text font-weight-regular",
          sortable: false,
          filterable: false,
        },
      ];
    },
  },

  created() {
    this.getSuppliers();
  },

  methods: {
    toRegisterSupplier() {
      this.$router.push({ name: "RegisterSupplier" });
    },

    toSupplierDetails(supId) {
      this.$router.push({ name: "ViewSupplierProfile", params: { id: supId } });
    },

    getSuppliers() {
      if (this.currentBusiness) {
        db.collection("suppliers")
          .where("businessID", "==", this.currentBusiness)
          .get()
          .then((querySnapshot) => {
            querySnapshot.forEach((doc) => {
              this.suppliers.push({
                id: doc.id,
                ...doc.data(),
              });
            });
            this.loading = false;
          });
      } else {
        db.collection("suppliers")
          .where("businessID", "in", this.ownersBusinessIds)
          .get()
          .then((querySnapshot) => {
            querySnapshot.forEach((doc) => {
              this.suppliers.push({
                id: doc.id,
                ...doc.data(),
              });
            });
            this.loading = false;
          });
      }
    },
    onSelectShop() {
      this.suppliers = [];
      this.loading = true;
      db.collection("suppliers")
        .where("businessID", "==", this.selectedShop)
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            this.suppliers.push({
              id: doc.id,
              ...doc.data(),
            });
          });
          this.loading = false;
        });
    },
    newSupplier() {
      this.$router.push({ name: "RegisterSupplier" });
    },
  },
};
</script>

<style></style>
